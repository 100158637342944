import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "topicos"
    }}>{`Topicos`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/windows"
        }}>{`Instalação no Windows`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/linux"
        }}>{`Instalação no Linux`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/download"
        }}>{`Download`}</a></li>
    </ul>
    <hr></hr>
    <p>{`© 2024 CMR Services. Todos os direitos reservados.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      